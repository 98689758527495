import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import "../../MainStyle.css";
import {
  API_ACCESS_URL,
  IP_ACCESS_URL,
  generateAuthToken,
} from "../modals/Constants";
import { setStorage, getStorage } from "../modals/Storage";
import TopBar from "../other-components/AuthTopBar/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Login() {
  const navigate = useNavigate();
  const [isInputValCorrect, setInValCorrect] = useState(false);
  const [isPasswordTypeShow, setPasswordType] = useState(false);
  const [tabActive, setTabActive] = useState("phone");

  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Login",
    inMobileNum: "",
    inPassword: "",
    toastDialogShow: false,
    toastTimeAvail: 7,
    toastMessage: "",
    isSessionExist: true,
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
      navigate("/withdraw", { replace: false });
    }

    if (data == "multiBtn2") {
      navigate("/register", { replace: false });
    }
  };

  const updatePasswordtype = () => {
    if (isPasswordTypeShow) {
      setPasswordType(false);
    } else {
      setPasswordType(true);
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateToastDialogState = (data, msg) => {
    setConstants((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setConstants((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const checkForInputVal = (mobilenum, password) => {
    if (
      mobilenum != "" &&
      mobilenum != undefined &&
      password != "" &&
      password != undefined
    ) {
      if (mobilenum.length == 10 && password.length >= 6) {
        setInValCorrect(true);
      } else {
        setInValCorrect(false);
      }
    } else {
      setInValCorrect(false);
    }
  };

  const onInputValChange = (source, data) => {
    if (source == "mobile") {
      checkForInputVal(data, pageConst.inPassword);

      setConstants((previousState) => {
        return { ...previousState, inMobileNum: data };
      });
    }

    if (source == "password") {
      checkForInputVal(pageConst.inMobileNum, data);

      setConstants((previousState) => {
        return { ...previousState, inPassword: data };
      });
    }
  };

  const validateMyIP = () => {
    let ip = "192.168.56.120";

    return validateLogin(ip);

    // const requestAPI = async (url) => {
    //   try {
    //     const res = await fetch(url, {
    //       method: "GET",
    //     });

    //     const data = await res.json();
    //     if (data.ip != "") {

    //     } else {
    //       return "IPUNKNOWN";
    //     }
    //   } catch (error) {
    //     return "IPOUTERROR";
    //   }
    // };

    // requestAPI(IP_ACCESS_URL);
  };

  const validateLogin = (ip) => {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            route: "route-login",
            AuthToken: generateAuthToken(pageConst.inMobileNum, ip),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "user_not_exist") {
          updateToastDialogState(true, "Account not exist!");
        } else if (data.status_code == "password_error") {
          updateToastDialogState(true, "Password not correct! try again");
        } else if (data.status_code == "success") {
          setStorage("uid", data.data[0].account_id, 30);
          setStorage("mobile", data.data[0].account_mobile_num, 30);
          setStorage("balance", data.data[0].account_balance, 30);
          setStorage("secret", data.data[0].auth_secret_key, 30);
          setStorage("isMusicOn", "true");
          navigate("/home", { replace: true });
        } else {
          updateToastDialogState(
            true,
            "Something went wrong! Please try again!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        updateToastDialogState(
          true,
          "There was a technical issue! Please try again!"
        );
      }
    }

    if (isInputValCorrect) {
      updateLoadingStatus(true);
      const formData = {
        LOGIN_ID: pageConst.inMobileNum,
        LOGIN_PASSWORD: pageConst.inPassword,
      };
      requestAPI(API_ACCESS_URL, formData);
    }
  };

  useEffect(() => {
    if (getStorage("uid")) {
      navigate("/home", { replace: true });
    } else {
      setConstants((previousState) => {
        return { ...previousState, isSessionExist: false };
      });
    }
  }, []);

  return (
    <div
      className={[
        general.viewCenter,
        general.appBackground,
        pageConst.isSessionExist == true ? general.hideView : "",
      ].join(" ")}
    >
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <LoadingDialog intentData={showLoading} />

        <div className={[general.posRelative]}>
          <TopBar />

          <div
            className={[
              styles.loginView,
              general.colView,
              general.flexDirColumn,
              general.width100,
              general.mgT50px,
            ].join(" ")}
          >
            <div
              className={[
                general.colView,
                general.pd15px20px,
                general.bgCustomColor1,
              ].join(" ")}
            >
              <span
                className={[
                  general.colorWhite,
                  general.fontS20Px,
                  pageConst.pageTitle == "" ? general.hideView : "",
                ].join(" ")}
              >
                {pageConst.pageTitle}
              </span>
              <span
                className={[
                  general.colorWhite,
                  general.fontS13Px,
                  general.mgT10px,
                ].join(" ")}
              >
                Please enter your mobile number to continue.<br></br>
                If you need support then please contact us.
              </span>
            </div>

            <div
              className={[styles.loginTabSelectView, styles.width100].join(" ")}
            >
              <div
                className={[
                  general.colView,
                  general.alignCenter,
                  general.width100,
                  general.pd10px,
                  tabActive == "phone" ? styles.loginTabSelectedView : "",
                ].join(" ")}
                onClick={() => setTabActive("phone")}
              >
                {tabActive == "phone" ? (
                  <img
                    src={require("../media/icons/phone_white_icon.png")}
                    alt="icon"
                  />
                ) : (
                  <img
                    src={require("../icons/mobile_not_active_icon.png")}
                    alt="icon"
                  />
                )}
                <span>Login via Phone</span>
              </div>

              <div
                className={[
                  general.colView,
                  general.alignCenter,
                  general.width100,
                  general.pd10px,
                  tabActive == "email" ? styles.loginTabSelectedView : "",
                ].join(" ")}
              >
                {tabActive == "email" ? (
                  <img
                    className="h-w-28"
                    src={require("../icons/email_active_icon.png")}
                    alt="icon"
                  />
                ) : (
                  <img
                    className="h-w-28"
                    src={require("../media/icons/email_icon.png")}
                    alt="icon"
                  />
                )}
                <span>Email Login</span>
              </div>
            </div>

            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
                general.mgT20px,
              ].join(" ")}
            >
              <div className={[general.rowView]}>
                <img
                  src={require("../media/icons/phone_active_icon.png")}
                  alt="icon"
                />
                <label>Phone Number</label>
              </div>

              <div
                className={[
                  general.width100,
                  general.rowView,
                  general.spaceBetween,
                  general.mgT10px,
                ].join(" ")}
              >
                <div
                  className={[
                    general.viewCenter,
                    general.height50px,
                    general.pd5px25px,
                    general.borderRadius10px,
                    general.bgWhite,
                  ].join(" ")}
                >
                  <span className={[general.colorGrey]}>+91</span>
                  <img
                    className={[
                      general.heightWidth18px,
                      general.mgL10px,
                      general.transformRotate90deg,
                    ].join(" ")}
                    src={require("../media/icons/angle_right_icon.png")}
                    alt="icon"
                  />
                </div>

                <input
                  type="number"
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.mgL10px,
                    general.borderRadius10px,
                    general.bgWhite,
                  ].join(" ")}
                  placeholder="Please enter phone number"
                  autoComplete="off"
                  onChange={(e) => onInputValChange("mobile", e.target.value)}
                ></input>
              </div>
            </div>

            <div
              className={[
                styles.inputView,
                general.colView,
                general.width100,
              ].join(" ")}
            >
              <div className={[general.rowView]}>
                <img
                  src={require("../media/icons/password_icon.png")}
                  alt="icon"
                />
                <label>Password</label>
              </div>

              <div className={[general.posRelative, general.mgT10px].join(" ")}>
                <input
                  type={isPasswordTypeShow ? "text" : "password"}
                  className={[
                    general.inputBox,
                    general.height50px,
                    general.borderRadius10px,
                    general.bgWhite,
                  ].join(" ")}
                  placeholder="Password (≥6 characters)"
                  autoComplete="new-password"
                  onChange={(e) => onInputValChange("password", e.target.value)}
                ></input>

                <div
                  className="ps-ab ps-tp-50 t-form-y ps-rgt-10"
                  onClick={(e) => updatePasswordtype(e)}
                >
                  {isPasswordTypeShow ? (
                    <img
                      className="h-w-22"
                      src={require("../icons/eye_open_icon.png")}
                    />
                  ) : (
                    <img
                      className="h-w-22"
                      src={require("../icons/eye_closed_icon.png")}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={[general.pd15px]}>
              <div
                className={[
                  general.height50px,
                  general.width100,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS20Px,
                  general.mgT10px,
                  general.borderRadius,
                  general.bgCustomColor2,
                ].join(" ")}
                onClick={() => validateMyIP()}
              >
                Login
              </div>

              <Link
                className={[
                  general.textDecoNone,
                  general.height50px,
                  general.width100,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS20Px,
                  general.mgT10px,
                  general.borderRadius,
                  general.bgCustomColor3,
                ].join(" ")}
                to={"/register"}
              >
                Register
              </Link>
            </div>

            <div
              className={[
                general.rowView,
                general.spaceBetween,
                general.pd20px40px50px40px,
              ].join(" ")}
            >
              <Link
                className={[
                  general.colView,
                  general.alignCenter,
                  general.textDecoNone,
                ].join(" ")}
                to={"/forgotpassword"}
              >
                <img
                  className={[general.heightWidth45px]}
                  src={require("../media/icons/password_icon.png")}
                  alt="icon"
                />
                <span
                  className={[general.fontS14Px, general.colorGrey].join(" ")}
                >
                  Forgot Password
                </span>
              </Link>

              <Link
                className={[
                  general.colView,
                  general.alignCenter,
                  general.textDecoNone,
                ].join(" ")}
                to={"/forgotpassword"}
              >
                <img
                  className={[general.heightWidth45px]}
                  src={require("../media/icons/customer_service_icon.png")}
                  alt="icon"
                />
                <span
                  className={[general.fontS14Px, general.colorGrey].join(" ")}
                >
                  Customer Service
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
