import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import "../../MainStyle.css";
import { API_ACCESS_URL } from "../modals/Constants";
import { getStorage } from "../modals/Storage";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function RechargeRecords() {
  const navigate = useNavigate();
  const listViewRef = useRef();
  const [showLoading, setShowLoading] = useState(false);

  const [pageConst, setConstants] = useState({
    pageTitle: "Deposit Records",
    pageCount: 1,
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data == "multiBtn2") {
      navigate("/addbankcard", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const onScroll = () => {
    if (listViewRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listViewRef.current;
      const isNearBottom = scrollTop + clientHeight >= scrollHeight;

      if (isNearBottom) {
        let newPageVal = pageConst.pageCount + 1;
        setConstants((previousState) => {
          return { ...previousState, pageCount: newPageVal };
        });
      }
    }
  };

  const updateRecordList = (data) => {
    let tempData = [];

    if (pageConst.recordList.length > 0) {
      tempData = pageConst.recordList;
    }

    for (let i = 0; i < data.length; i++) {
      let rechargeDetailsArr = data[i]["r_details"].split(",");

      tempData.push(
        <div key={i} className="pr-v w-100 br-5 mg-t-15 bg-extm-l-white">
          <div
            className={`ps-ab ps-tp ps-lf pd-2-8 br-5 ft-sz-15 cl-white ${
              data[i]["r_status"] == "success"
                ? "bg-green"
                : data[i]["r_status"] == "rejected"
                ? "bg-red"
                : "bg-l-black"
            }`}
          >
            {data[i]["r_status"] == "success"
              ? "completed"
              : data[i]["r_status"] == "rejected"
              ? "cancelled"
              : "processing"}
          </div>
          <div className="ps-ab ps-tp ps-rgt pd-2-8 ft-sz-15">
            {data[i]["r_date"] + " " + data[i]["r_time"]}
          </div>

          <div className="col-view pd-5-15">
            <div className="pr-v col-view w-100 ft-sz-14 mg-t-20">
              <span className="ft-sz-15 mg-t-5">By: UTRPay</span>
              <span className="ft-sz-15 mg-t-5">
                To: <span className="cl-blue">{rechargeDetailsArr[1]}</span>
              </span>

              <div
                className={`ps-ab ps-btm ps-rgt pd-2-8 ft-sz-25 ${
                  data[i]["r_status"] == "success"
                    ? "cl-green"
                    : data[i]["r_status"] == "rejected"
                    ? "cl-red"
                    : ""
                }`}
              >
                ₹{data[i]["r_amount"]}
              </div>
            </div>
          </div>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, recordList: tempData };
    });
  };

  function getRechargeRecords(pageCount) {
    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            route: "route-recharge-records",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if ((data.status_code = "success")) {
          updateRecordList(data.data);
        }
      } catch (error) {
        updateLoadingStatus(false);
      }
    };

    updateLoadingStatus(true);
    fecthApiData(
      API_ACCESS_URL +
        "?USER_ID=" +
        getStorage("uid") +
        "&PAGE_NUM=" +
        pageCount
    );
  }

  useEffect(() => {
    getRechargeRecords(pageConst.pageCount);
    const listInnerElement = listViewRef.current;

    if (listInnerElement) {
      listInnerElement.addEventListener("scroll", onScroll);

      // Clean-up
      return () => {
        listInnerElement.removeEventListener("scroll", onScroll);
      };
    }
  }, [pageConst.pageCount]);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
        ref={listViewRef}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        <div className={[general.colView, general.mgT50px].join(" ")}>
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view pd-5-15">{pageConst.recordList}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RechargeRecords;
