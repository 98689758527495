import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BettingDialog from "../../dialogs/BettingDialog";
import MaintenanceDialog from "../../dialogs/MaintenanceDialog";
import ToastDialog from "../../dialogs/ToastDialog";
import WinDialog from "../../dialogs/WinLoseDialog/dialog";
import RuleDialog from "../../dialogs/K3LotteryDialog";
import { setStorage, getStorage } from "../../modals/Storage";
import { API_ACCESS_URL, openNewPage } from "../../modals/Constants";
import "../../../MainStyle.css";
import styles from "../k3Lottery/Game.module.css";

import audio1 from "../../media/audio/1.mp3";
import audio2 from "../../media/audio/2.mp3";
import ImportantNotice from "../../other-components/ImportantNotice/Notice";
import LoadingDialog from "./components/LoadingDialog/dialog";

function K3Lottery3Min() {
  const navigate = useNavigate();
  const [remainingSec, setCount] = useState(60);
  const [disableTime, setDisable] = useState(30);

  const [isMusicOn, setIsMusicOn] = useState(getStorage("isMusicOn"));
  const [playComponent, setPlayComponent] = useState(0);

  const [diceAnimTime, setDiceAnimTime] = useState(-1);
  const [dice1Num, setDice1Num] = useState(1);
  const [dice2Num, setDice2Num] = useState(1);
  const [dice3Num, setDice3Num] = useState(1);

  const [dice1RollNum, setDice1RollNum] = useState(1);
  const [dice2RollNum, setDice2RollNum] = useState(1);
  const [dice3RollNum, setDice3RollNum] = useState(1);

  const [shouldWinLossDialogShow, setShouldWinLossDialogShow] = useState(false);

  const [selectedRecord, setSelectedRecord] = useState(0);
  const [showLoading, setShowLoading] = useState(true);

  const [pageConst, setGameData] = useState({
    pageTitle: "K3 Lottery",
    gamePeriodId: "",
    gameCode: "K3LOTTERY3MIN",
    remainingSec1: 0,
    remainingSec2: 0,
    remainingMin1: 0,
    remainingMin2: 0,
    isControlEnable: true,
    requestAccountData: true,
    maintenanceDialogShow: false,
    ruleDialogShow: false,
    winDialogShow: false,
    toastDialogShow: false,
    toastMessage: "",
    tabActiveReord: "everyoneorder",
    gameWinDetails: [],
    gameRecordsList: [],
    gameShortRecordList: [],
    mygameRecordsList: [],
  });

  const [bettingDialogConst, setBettingDialogConst] = useState({
    bettingDialogShow: false,
    bettingDialogTitle: "Join Green",
    bettingDialogTheme: "dlg-thm-green",
    gameBetRoutePath: "route-bet-common",
    gameSelectedBet: "",
    gameSelectedMultiply: "1",
  });

  const [winDialogConst, setWinDialogConst] = useState({
    winDialogShow: false,
    constPeriodId: "",
    constWinAmount: 0,
    constWinDialogResult: "",
    constWinDialogState: "",
  });

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState === "visible";
    if (isVisible) {
      getGameData();
    }
  }, []);

  const updateSelectedRecord = (data) => {
    if (selectedRecord != 0 && selectedRecord == data) {
      setSelectedRecord(0);
    } else {
      setSelectedRecord(data);
    }
  };

  const updateMusicStatus = (data) => {
    setIsMusicOn(data);
    setStorage("isMusicOn", data);
  };

  const openNewGame = (data) => {
    if (data == "1") {
      navigate("/k3lottery1min", { replace: true });
    } else if (data == "2") {
      navigate("/k3lottery3min", { replace: true });
    } else if (data == "3") {
      navigate("/k3lottery5min", { replace: true });
    } else if (data == "4") {
      navigate("/k3lottery10min", { replace: true });
    }
  };

  const updatePeriod = (data) => {
    setGameData((previousState) => {
      return { ...previousState, gamePeriodId: data[0].game_period_id };
    });
  };

  const updateWinDialogState = (data) => {
    setWinDialogConst((previousState) => {
      return { ...previousState, winDialogShow: data };
    });
  };

  const updateGameControl = (data) => {
    setGameData((previousState) => {
      return { ...previousState, isControlEnable: data };
    });
  };

  const updateReqAcntData = (data) => {
    if (data == "false") {
      setGameData((previousState) => {
        return { ...previousState, requestAccountData: false };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, requestAccountData: true };
      });
    }
  };

  const updateRemainSec = (data, val) => {
    if (data == "1") {
      setGameData((previousState) => {
        return { ...previousState, remainingSec1: val };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, remainingSec2: val };
      });
    }
  };

  const updateRemainMin = (data, val) => {
    if (data == "1") {
      setGameData((previousState) => {
        return { ...previousState, remainingMin1: val };
      });
    } else {
      setGameData((previousState) => {
        return { ...previousState, remainingMin2: val };
      });
    }
  };

  const updateActiveTab = (data) => {
    setGameData((previousState) => {
      return { ...previousState, tabActiveReord: data };
    });
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const getDialogThemeClassName = (data) => {
    let returnVal = "dlg-thm-green";

    if (data == "r" || data == "rv" || data == "o") {
      returnVal = "dlg-thm-red";
    } else if (data == "v") {
      returnVal = "dlg-thm-violet";
    } else if (data == "b") {
      returnVal = "dlg-thm-orange";
    } else if (data == "s") {
      returnVal = "dlg-thm-blue";
    }

    return returnVal;
  };

  const updateBettingDialogState = (e, data, selected, title) => {
    if (selected != "") {
      setBettingDialogConst((previousState) => {
        return { ...previousState, gameSelectedBet: selected };
      });
    }

    if (
      e != null &&
      e.target.className ==
        "ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"
    ) {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: data };
      });
    } else if (data == true && pageConst.isControlEnable) {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: data };
      });

      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogTitle: title };
      });

      setBettingDialogConst((previousState) => {
        return {
          ...previousState,
          bettingDialogTheme: getDialogThemeClassName(
            getColourCodeVal(selected)
          ),
        };
      });
    } else if (data == "dismiss") {
      setBettingDialogConst((previousState) => {
        return { ...previousState, bettingDialogShow: false };
      });
    }
  };

  const showGameRules = () => {
    setGameData((previousState) => {
      return { ...previousState, ruleDialogShow: true };
    });
  };

  const topBarClickAction = (data) => {
    if (data == "multiBtn1") {
    } else {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: true };
      });
    }
  };

  const updateMaintenanceDialog = (data) => {
    if (data == "dismiss") {
      navigate(-1);
    } else if (data == "true") {
      setGameData((previousState) => {
        return { ...previousState, maintenanceDialogShow: true };
      });
    }
  };

  const updateGameRuleDialog = (e, data) => {
    if (
      e != null &&
      e.target.className ==
        "ps-fx h-100vh res-wth z-i--100 bg-l-black rul-dlg activeDialog"
    ) {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: data };
      });
    } else if (data == "dismiss") {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: false };
      });
    } else if (data == "true") {
      setGameData((previousState) => {
        return { ...previousState, ruleDialogShow: true };
      });
    }
  };

  const updateToastDialogState = (data, msg) => {
    setGameData((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setGameData((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const getCapitalLetter = (data) => {
    let returnVal = "";

    if (data == "red") {
      returnVal = "R";
    } else if (data == "green") {
      returnVal = "G";
    } else if (data == "violet") {
      returnVal = "V";
    } else if (data == "b") {
      returnVal = "B";
    } else if (data == "s") {
      returnVal = "S";
    } else if (data == "o") {
      returnVal = "O";
    } else if (data == "e") {
      returnVal = "E";
    } else {
      returnVal = data;
    }

    return returnVal;
  };

  const isBigSmall = (data) => {
    let returnVal = "false";

    if (data == "b" || data == "s") {
      returnVal = "true";
    }

    return returnVal;
  };

  const getBigSmall = (data) => {
    let returnVal = "";

    if (data > 10) {
      returnVal = "B";
    } else {
      returnVal = "S";
    }

    return returnVal;
  };

  const getWinCode = (data, isWin) => {
    let returnVal = "";

    if (isWin == "loss" && data == "s") {
      returnVal = "B";
    } else if (isWin == "loss" && data == "b") {
      returnVal = "S";
    } else if (isWin == "profit") {
      returnVal = getCapitalLetter(data);
    } else {
      returnVal = "";
    }

    return returnVal;
  };

  const getColourCodeVal = (data) => {
    let returnVal = "";

    if (
      data == "3" ||
      data == "5" ||
      data == "7" ||
      data == "9" ||
      data == "11" ||
      data == "13" ||
      data == "15" ||
      data == "17"
    ) {
      returnVal = "r";
    } else if (
      data == "4" ||
      data == "6" ||
      data == "8" ||
      data == "10" ||
      data == "12" ||
      data == "14" ||
      data == "16" ||
      data == "18"
    ) {
      returnVal = "g";
    } else if (data == "b" || data == "B") {
      returnVal = "b";
    } else if (data == "s" || data == "S") {
      returnVal = "s";
    } else if (data == "o" || data == "O") {
      returnVal = "o";
    } else if (data == "e" || data == "E") {
      returnVal = "e";
    }

    return returnVal;
  };

  const checkIfThisNumber = (data) => {
    return !isNaN(parseFloat(data));
  };

  const updateGameRecords = (data) => {
    let tempData = [];
    let shortCutData = [];

    for (let i = 0; i < data.length; i++) {
      let tempMatchResult = data[i]["match_result"];
      let matchResult = 0,
        dice1Num = 0,
        dice2Num = 0,
        dice3Num = 0;

      for (let i = 0; i < tempMatchResult.length; i++) {
        matchResult += Number(tempMatchResult[i]);
        if (i == 0) {
          dice1Num = Number(tempMatchResult[i]);
        } else if (i == 1) {
          dice2Num = Number(tempMatchResult[i]);
        } else if (i == 2) {
          dice3Num = Number(tempMatchResult[i]);
        }
      }

      if (i == 0) {
        let dice1RollNum = 1,
          dice2RollNum = 1,
          dice3RollNum = 1;
        for (let i = 0; i < tempMatchResult.length; i++) {
          if (i == 0) {
            dice1RollNum = Number(tempMatchResult[i]);
            setDice1RollNum(Number(tempMatchResult[i]));
          } else if (i == 1) {
            dice2RollNum = Number(tempMatchResult[i]);
            setDice2RollNum(Number(tempMatchResult[i]));
          } else if (i == 2) {
            dice3RollNum = Number(tempMatchResult[i]);
            setDice3RollNum(Number(tempMatchResult[i]));
          }
        }

        if (diceAnimTime == 0) {
          setDiceAnimTime(3);
        } else {
          showDiceAnimation(dice1RollNum, dice2RollNum, dice3RollNum);
        }
      }

      if (i < 5) {
        shortCutData.push(
          <div key={data[i]["period_id"] * 2} className="row-view sb-view">
            {matchResult == "0" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n0_icon.png")}
                alt="icon"
              />
            ) : matchResult == "1" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n1_icon.png")}
                alt="icon"
              />
            ) : matchResult == "2" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n2_icon.png")}
                alt="icon"
              />
            ) : matchResult == "3" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n3_icon.png")}
                alt="icon"
              />
            ) : matchResult == "4" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n4_icon.png")}
                alt="icon"
              />
            ) : matchResult == "5" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n5_icon.png")}
                alt="icon"
              />
            ) : matchResult == "6" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n6_icon.png")}
                alt="icon"
              />
            ) : matchResult == "7" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n7_icon.png")}
                alt="icon"
              />
            ) : matchResult == "8" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n8_icon.png")}
                alt="icon"
              />
            ) : matchResult == "9" ? (
              <img
                className="result-shortcut-icon"
                src={require("../Wingo/icons/n9_icon.png")}
                alt="icon"
              />
            ) : (
              ""
            )}
          </div>
        );
      }

      tempData.push(
        <div
          key={data[i]["period_id"]}
          className={[
            styles.rowView,
            styles.jcSpaceBet,
            styles.pd10px,
          ].join(" ")}
        >
          <span className={[styles.w50px, styles.fontS14Px].join(" ")}>
            {data[i]["period_id"]}
          </span>
          <span
            className={[
              styles.fontS14Px,
              styles.w40px,
              styles.fontW400,
              styles.mgL5px,
              `${
                getColourCodeVal(matchResult) == "r"
                  ? "cl-red"
                  : getColourCodeVal(matchResult) == "g"
                  ? "cl-green"
                  : getColourCodeVal(matchResult) == "rv"
                  ? "cl-rv"
                  : "cl-gv"
              }`,
            ].join(" ")}
          >
            {matchResult}
            <span className={[styles.fontS14Px, styles.mgL10px].join(" ")}>
              {Number(matchResult) > 10 ? "Big" : "Small"}
            </span>
          </span>
          <span
            className={[
              styles.viewCenter,
              styles.fontS14Px,
              styles.w30px,
              styles.mgL5px,
            ].join(" ")}
          >
            {Number(matchResult) % 2 == 0 ? "Even" : "Odd"}
          </span>

          <div className={[styles.viewCenter]}>
            <div
              className={[
                styles.smallDiceView,
                `${
                  dice1Num == 1
                    ? styles.smallDiceView1
                    : dice1Num == 2
                    ? styles.smallDiceView2
                    : dice1Num == 3
                    ? styles.smallDiceView3
                    : dice1Num == 4
                    ? styles.smallDiceView4
                    : dice1Num == 5
                    ? styles.smallDiceView5
                    : dice1Num == 6
                    ? styles.smallDiceView6
                    : ""
                }`,
              ].join(" ")}
            ></div>

            <div
              className={[
                styles.smallDiceView,
                `${
                  dice2Num == 1
                    ? styles.smallDiceView1
                    : dice2Num == 2
                    ? styles.smallDiceView2
                    : dice2Num == 3
                    ? styles.smallDiceView3
                    : dice2Num == 4
                    ? styles.smallDiceView4
                    : dice2Num == 5
                    ? styles.smallDiceView5
                    : dice2Num == 6
                    ? styles.smallDiceView6
                    : ""
                }`,
              ].join(" ")}
            ></div>

            <div
              className={[
                styles.smallDiceView,
                `${
                  dice3Num == 1
                    ? styles.smallDiceView1
                    : dice3Num == 2
                    ? styles.smallDiceView2
                    : dice3Num == 3
                    ? styles.smallDiceView3
                    : dice3Num == 4
                    ? styles.smallDiceView4
                    : dice3Num == 5
                    ? styles.smallDiceView5
                    : dice3Num == 6
                    ? styles.smallDiceView6
                    : ""
                }`,
              ].join(" ")}
            ></div>
          </div>
        </div>
      );
    }

    setGameData((previousState) => {
      return { ...previousState, gameRecordsList: tempData };
    });

    setGameData((previousState) => {
      return { ...previousState, gameShortRecordList: shortCutData };
    });
  };

  const getFixedDecimalVal = (data) => {
    return Number(data).toFixed();
  };

  const updateWinLossDialog = (
    periodId,
    wonAmount,
    matchResult,
    winLossStatus
  ) => {
    let tempResult = null;

    if (winLossStatus != "wait") {
      let tempMatchResult = 0;

      for (let i = 0; i < matchResult.length; i++) {
        tempMatchResult += Number(matchResult[i]);
      }

      tempResult = `
      <span>Lottery Result</span>
      <span
      style="background: ${
        getColourCodeVal(getBigSmall(Number(tempMatchResult))) == "b"
          ? "#fc940e"
          : getColourCodeVal(getBigSmall(Number(tempMatchResult))) == "s"
          ? "#0093ff"
          : getColourCodeVal(getBigSmall(Number(tempMatchResult))) == "o"
          ? "#fa3c09"
          : "#00c282"
      }; color: #FFFFFF;
        padding: 2px 8px;border-radius: 5px;">
          ${
            getColourCodeVal(getBigSmall(Number(tempMatchResult))) == "b"
              ? "Big"
              : getColourCodeVal(getBigSmall(Number(tempMatchResult))) == "s"
              ? "Small"
              : getColourCodeVal(getBigSmall(Number(tempMatchResult))) == "o"
              ? "Odd"
              : "Even"
          }
      </span>
      <span
      style="background: ${
        getColourCodeVal(Number(tempMatchResult)) == "g"
          ? "#00c282"
          : getColourCodeVal(Number(tempMatchResult)) == "r"
          ? "#fa3c09"
          : "#0093ff"
      }; color: #FFFFFF;
        height: 25px; width: 25px;border-radius: 50%;">${tempMatchResult}</span>`;

      setWinDialogConst((previousState) => {
        return {
          ...previousState,
          constPeriodId: "win 1min " + periodId,
        };
      });

      if (winLossStatus == "profit") {
        setWinDialogConst((previousState) => {
          return { ...previousState, constWinAmount: wonAmount };
        });

        setWinDialogConst((previousState) => {
          return { ...previousState, constWinDialogState: "win" };
        });
      } else {
        setWinDialogConst((previousState) => {
          return { ...previousState, constWinAmount: "0" };
        });

        setWinDialogConst((previousState) => {
          return { ...previousState, constWinDialogState: "loss" };
        });
      }

      setWinDialogConst((previousState) => {
        return { ...previousState, constWinDialogResult: tempResult };
      });

      setStorage("recentk3lottery3minbet", "");
      updateWinDialogState(true);
    }
  };

  const recentBetDetails = (invested_amount) => {
    setStorage("recentk3lottery3minbet", pageConst.gamePeriodId);

    let tempData = [];

    tempData.push(
      <div key={60} className={[styles.myGameRecordsViewItem].join(" ")}>
        <div
          className={[
            styles.myGameRecordsContents,
            styles.rowView,
            styles.jcSpaceBet,
            styles.alignItemsCenter,
          ].join(" ")}
        >
          <div
            className={[
              styles.viewCenter,
              styles.resultShortView,
              getColourCodeVal(bettingDialogConst.gameSelectedBet) == "r"
                ? styles.bgRed
                : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "g"
                ? styles.bgGreen
                : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "rv"
                ? styles.bgGreen
                : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "gv"
                ? styles.bgGreen
                : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "b"
                ? styles.bgOrange
                : styles.bgBlue,
            ].join(" ")}
          >
            {getColourCodeVal(bettingDialogConst.gameSelectedBet) == "b"
              ? "big"
              : getColourCodeVal(bettingDialogConst.gameSelectedBet) == "s"
              ? "small"
              : checkIfThisNumber(bettingDialogConst.gameSelectedBet)
              ? bettingDialogConst.gameSelectedBet
              : ""}
          </div>

          <div
            className={[styles.colView, styles.mygameShortDetailsView].join(
              " "
            )}
          >
            <div
              className={[styles.w100, styles.rowView, styles.jcSpaceBet].join(
                " "
              )}
            >
              <span className={[styles.letterSpacing1p1px]}>
                bettingDialogConst.gamePeriodId
              </span>
              <span></span>
            </div>

            <div
              className={[
                styles.w100,
                styles.rowView,
                styles.jcSpaceBet,
                styles.mgT5px,
              ].join(" ")}
            >
              <span className={[styles.fontS13Px]}>--</span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    );

    let finalArr = [tempData].concat(pageConst.mygameRecordsList);

    setGameData((previousState) => {
      return { ...previousState, mygameRecordsList: finalArr };
    });
  };

  const updateMyGameRecords = (data) => {
    if (data.length > 0) {
      let i = 0;
      if (
        i == 0 &&
        getStorage("recentk3lottery3minbet") == data[i]["m_period_id"]
      ) {
        updateWinLossDialog(
          data[0]["m_period_id"],
          data[0]["m_profit"],
          data[0]["m_result"],
          data[0]["m_status"]
        );
      }

      setGameData((previousState) => {
        return { ...previousState, mygameRecordsList: data };
      });
    }
  };

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  function getGameData() {
    updateLoadingStatus(true);

    const fecthApiData = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-setup-k3lottery",
            AuthToken: getStorage("secret"),
          },
        });

        const data = await res.json();

        updateLoadingStatus(false);

        if (data.status_code == "success") {
          updatePeriod(data.gamedata);
          setCount(data.gamedata[0].game_remain_seconds);
          setDisable(data.gamedata[0].game_disable_time);
          updateGameRecords(data.matchrecords);
          updateMyGameRecords(data.mymatchrecords);
          setStorage("balance", data.account_balance);
        } else if (data.status_code == "under_maintenance") {
          updateMaintenanceDialog("true");
        } else {
          updateToastDialogState(
            true,
            "Something went wrong! Please try again or login!"
          );
        }
      } catch (error) {
        updateLoadingStatus(false);
        console.log(error);
      }
    };

    fecthApiData(
      API_ACCESS_URL +
        "?USER_ID=" +
        getStorage("uid") +
        "&PROJECT_NAME=" +
        pageConst.gameCode
    );
  }

  function setUpTimer(data) {
    if (data == "true") {
      updateReqAcntData("true");
    }

    if (pageConst.requestAccountData == true) {
      updateReqAcntData("false");
      getGameData();
    }
  }

  const showDiceAnimation = (dice1, dice2, dice3) => {
    if (
      Number.isInteger(dice1) &&
      Number.isInteger(dice2) &&
      Number.isInteger(dice3)
    ) {
      setDice1Num(dice1);
      setDice2Num(dice2);
      setDice3Num(dice3);
    } else {
      setDice1Num(getRandomNum(1, 6, "interger"));
      setDice2Num(getRandomNum(1, 6, "interger"));
      setDice3Num(getRandomNum(1, 6, "interger"));
    }
  };

  useEffect(() => {
    if (isMusicOn == "true" && playComponent > 1 && playComponent <= 4) {
      let audio = new Audio(audio1);
      audio.play();
    } else if (isMusicOn == "true" && playComponent == 1) {
      let audio = new Audio(audio2);
      audio.play();
    }
  }, [playComponent, setIsMusicOn]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    setUpTimer("false");

    const milliSecInterval = setInterval(() => {
      if (diceAnimTime > 0) {
        setDiceAnimTime(diceAnimTime - 1);
        showDiceAnimation("rand", "rand", "rand");
      } else if (diceAnimTime == 0) {
        if (shouldWinLossDialogShow) {
          setShouldWinLossDialogShow(false);
          updateWinDialogState(true);
        }

        setDiceAnimTime(-1);
        showDiceAnimation(dice1RollNum, dice2RollNum, dice3RollNum);
      }
    }, 100);

    const interval = setInterval(() => {
      if (remainingSec >= 0) {
        setCount(remainingSec - 1);

        if (remainingSec <= 0) {
          setDiceAnimTime(0);
          setPlayComponent(0);
          setUpTimer("true");
          setCount(60);
        } else {
          function splitIntoArray(num) {
            return Array.from(String(num), Number);
          }

          const minutes = Math.floor(remainingSec / 60);
          const seconds = remainingSec % 60;

          if (minutes < 10) {
            updateRemainMin("1", 0);
            updateRemainMin("2", minutes);
          } else {
            var numArr = splitIntoArray(minutes);
            updateRemainMin("1", numArr[0]);
            updateRemainMin("2", numArr[1]);
          }

          if (seconds < 10) {
            if (seconds <= 4) {
              setPlayComponent(seconds);
            }

            updateRemainSec("1", 0);
            updateRemainSec("2", seconds);
          } else {
            var numArr = splitIntoArray(seconds);
            updateRemainSec("1", numArr[0]);
            updateRemainSec("2", numArr[1]);
          }

          if (remainingSec < disableTime && pageConst.isControlEnable == true) {
            updateGameControl(false);
          } else if (
            remainingSec > disableTime &&
            pageConst.isControlEnable == false
          ) {
            updateGameControl(true);
          }
        }
      }
    }, 1000);

    //Clearing the interval
    return () => {
      clearInterval(interval);
      clearInterval(milliSecInterval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [remainingSec]);

  return (
    <div className="v-center bg-black">
      <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-grey">
        <LoadingDialog intentData={showLoading} />
        <WinDialog
          intentData={winDialogConst}
          updateState={updateWinDialogState}
        />
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />
        <RuleDialog intentData={pageConst} updateState={updateGameRuleDialog} />
        <BettingDialog
          gameCode={pageConst.gameCode}
          dialogData={bettingDialogConst}
          updateState={updateBettingDialogState}
          toastUpdate={updateToastDialogState}
          recentBetDetails={recentBetDetails}
        />
        <MaintenanceDialog
          intentData={pageConst}
          updateState={updateMaintenanceDialog}
        />

        <div className={[styles.colView, styles.bgGrey].join(" ")}>
          <div className={[styles.borderBLR50px, styles.bgCustom].join(" ")}>
            <div className={[styles.GameTopBar, styles.bgCustom].join(" ")}>
              <Link onClick={() => navigate(-1)}>
                <img
                  src={require("../Wingo/icons/angle_left_white_icon.png")}
                  alt="icon"
                />
              </Link>

              <img src={require("../../media/icons/app_logo.png")} alt="icon" />

              <div className="row-view v-center">
                <img
                  className="h-27-p"
                  src={require("../../icons/live_chat_icon.png")}
                  onClick={() => openNewPage(getStorage("liveChatURL"))}
                />

                <img
                  className={`h-27-p mg-l-10 ${
                    isMusicOn == "false" ? "hide-v" : ""
                  }`}
                  src={require("../../icons/music_on_icon.png")}
                  onClick={() => updateMusicStatus("false")}
                />
                <img
                  className={`h-27-p mg-l-10 ${
                    isMusicOn == "true" ? "hide-v" : ""
                  }`}
                  src={require("../../icons/music_off_icon.png")}
                  onClick={() => updateMusicStatus("true")}
                />
              </div>
            </div>

            <div className={[styles.pd15px, styles.mgT60px].join(" ")}>
              <div className="col-view v-center pd-10 br-15 bg-white">
                <div className="row-view">
                  <p className="cl-black ft-sz-23 ft-wgt-500">
                    ₹{Number(getStorage("balance")).toLocaleString()}
                  </p>
                  <img
                    className="h-25-p mg-l-10"
                    src={require("../../media/icons/refresh_icon.png")}
                    alt="icon"
                    onClick={() => setUpTimer("true")}
                  />
                </div>
                <p className="row-view ft-sz-14 cl-drk-black">Wallet Balance</p>

                <div className="res-wallet-btns v-center w-100 mg-t-15">
                  <Link
                    className="v-center cl-white ft-wgt-500 br-20 bg-red-grad-2"
                    to={"/recharge"}
                    style={{ background: "#61A9FF" }}
                  >
                    Deposit
                  </Link>
                  <Link
                    className="v-center cl-grey ft-wgt-500 br-20 br-a-grey bg-transparent"
                    to={"/withdraw"}
                  >
                    Withdraw
                  </Link>
                </div>
              </div>
            </div>

            <div className={[styles.pd0px15px]}>
              <ImportantNotice />
            </div>

            <div className={[styles.pd0px15px, styles.mgT15px].join(" ")}>
              <div className={[styles.GameOptionsChooseView]}>
                <div
                  className={[
                    styles.viewCenter,
                    styles.flexDCol,
                    styles.GameOptionsChooseViewItem,
                  ].join(" ")}
                  onClick={() => openNewGame("1")}
                >
                  <img
                    className="h-50-p"
                    src={require("../Wingo/icons/2.png")}
                    alt="icon"
                  />
                  <span>K3Lottery</span>
                  <span>1Min</span>
                </div>

                <div
                  className={[
                    styles.viewCenter,
                    styles.flexDCol,
                    styles.GameOptionsChooseViewItem,
                    styles.GameOptionsSelectedView,
                  ].join(" ")}
                  onClick={() => openNewGame("2")}
                >
                  <img
                    className="h-50-p"
                    src={require("../Wingo/icons/1.png")}
                    alt="icon"
                  />
                  <span>K3Lottery</span>
                  <span>3Min</span>
                </div>

                <div
                  className={[
                    styles.viewCenter,
                    styles.flexDCol,
                    styles.GameOptionsChooseViewItem,
                  ].join(" ")}
                  onClick={() => openNewGame("3")}
                >
                  <img
                    className="h-50-p"
                    src={require("../Wingo/icons/2.png")}
                    alt="icon"
                  />
                  <span>K3Lottery</span>
                  <span>5Min</span>
                </div>

                <div
                  className={[
                    styles.viewCenter,
                    styles.flexDCol,
                    styles.GameOptionsChooseViewItem,
                  ].join(" ")}
                  onClick={() => openNewGame("4")}
                >
                  <img
                    className="h-50-p"
                    src={require("../Wingo/icons/2.png")}
                    alt="icon"
                  />
                  <span>K3Lottery</span>
                  <span>10Min</span>
                </div>
              </div>
            </div>
          </div>

          <div className={`ps-rl col-view pd-10 mg-10-15 br-10 bg-white`}>
            <div className="row-view sb-view">
              <div className="col-view pd-10">
                <div
                  className="w-fit-content txt-deco-n pd-5-10 ft-sz-13 cl-red ft-wgt-500 br-5 br-a-primary bg-transparent"
                  onClick={() => showGameRules()}
                >
                  How to play
                </div>

                <span className="res-ft-sz-18 ltr-s-2p2 ft-wgt-600 mg-t-10">
                  {pageConst.gamePeriodId}
                </span>
              </div>

              <div className="col-view pd-10">
                <div className="col-view a-right">
                  <span className="ft-sz-14">Time Remaining</span>
                  <div
                    className={[styles.countDownTimerView, styles.mgT10px].join(
                      " "
                    )}
                  >
                    <span>{pageConst.remainingMin1}</span>
                    <span>{pageConst.remainingMin2}</span>:
                    <span>{pageConst.remainingSec1}</span>
                    <span>{pageConst.remainingSec2}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={[styles.lotteryResultView]}>
              <div className={[styles.lotteryCubesHolder]}>
                <div
                  className={[
                    styles.diceView,
                    `${
                      dice1Num == 1
                        ? styles.diceView1
                        : dice1Num == 2
                        ? styles.diceView2
                        : dice1Num == 3
                        ? styles.diceView3
                        : dice1Num == 4
                        ? styles.diceView4
                        : dice1Num == 5
                        ? styles.diceView5
                        : dice1Num == 6
                        ? styles.diceView6
                        : ""
                    }`,
                  ].join(" ")}
                ></div>
                <div
                  className={[
                    styles.diceView,
                    `${
                      dice2Num == 1
                        ? styles.diceView1
                        : dice2Num == 2
                        ? styles.diceView2
                        : dice2Num == 3
                        ? styles.diceView3
                        : dice2Num == 4
                        ? styles.diceView4
                        : dice2Num == 5
                        ? styles.diceView5
                        : dice2Num == 6
                        ? styles.diceView6
                        : ""
                    }`,
                  ].join(" ")}
                ></div>
                <div
                  className={[
                    styles.diceView,
                    `${
                      dice3Num == 1
                        ? styles.diceView1
                        : dice3Num == 2
                        ? styles.diceView2
                        : dice3Num == 3
                        ? styles.diceView3
                        : dice3Num == 4
                        ? styles.diceView4
                        : dice3Num == 5
                        ? styles.diceView5
                        : dice3Num == 6
                        ? styles.diceView6
                        : ""
                    }`,
                  ].join(" ")}
                ></div>
              </div>
            </div>

            <div
              className={`ps-rl ${
                !pageConst.isControlEnable
                  ? playComponent <= 0
                    ? "disable-view"
                    : ""
                  : ""
              }`}
            >
              <div
                className={[
                  styles.viewCenter,
                  styles.timeOutView,
                  !pageConst.isControlEnable
                    ? playComponent <= 4 && playComponent > 0
                      ? ""
                      : styles.hideView
                    : styles.hideView,
                ].join(" ")}
              >
                <h1 className={[styles.viewCenter]}>0</h1>
                <h1 className={[styles.viewCenter, styles.mgL15px].join(" ")}>
                  {playComponent}
                </h1>
              </div>

              <div
                className={[
                  styles.joinBallView,
                  "pd-10",
                  "br-10",
                  "bg-grey",
                ].join(" ")}
              >
                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.redBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.redBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "3", "Join 3")
                      }
                    >
                      3
                    </div>
                  </div>

                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.greenBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.greenBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "4", "Join 4")
                      }
                    >
                      4
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.redBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.redBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "5", "Join 5")
                      }
                    >
                      5
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.greenBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.greenBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "6", "Join 6")
                      }
                    >
                      6
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.redBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.redBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "7", "Join 7")
                      }
                    >
                      7
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.greenBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.greenBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "8", "Join 8")
                      }
                    >
                      8
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.redBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.redBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "9", "Join 9")
                      }
                    >
                      9
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.greenBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.greenBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "10", "Join 10")
                      }
                    >
                      10
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.redBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.redBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "11", "Join 11")
                      }
                    >
                      11
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.greenBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.greenBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "12", "Join 12")
                      }
                    >
                      12
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.redBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.redBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "13", "Join 13")
                      }
                    >
                      13
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.greenBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.greenBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "14", "Join 14")
                      }
                    >
                      14
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.redBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.redBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "15", "Join 15")
                      }
                    >
                      15
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.greenBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.greenBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "16", "Join 16")
                      }
                    >
                      16
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.redBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.redBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "17", "Join 17")
                      }
                    >
                      17
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>

                <div className={[styles.viewCenter, styles.flexDCol].join(" ")}>
                  <div
                    className={[
                      styles.ballView,
                      styles.greenBall,
                      styles.viewCenter,
                    ].join(" ")}
                  >
                    <div
                      className={[
                        styles.greenBallTxt,
                        styles.fontS25Px,
                        styles.fontW700,
                      ].join(" ")}
                      onClick={() =>
                        updateBettingDialogState(null, true, "18", "Join 18")
                      }
                    >
                      18
                    </div>
                  </div>
                  <span className={[styles.fontS13Px]}>7x</span>
                </div>
              </div>

              <div
                className={[styles.joinBigSmallOddEvenView, "row-view"].join(
                  " "
                )}
              >
                <div
                  className="col-view v-center"
                  onClick={() =>
                    updateBettingDialogState(null, true, "b", "Join Big")
                  }
                >
                  Big
                  <span>(2x)</span>
                </div>

                <div
                  className="col-view v-center"
                  onClick={() =>
                    updateBettingDialogState(null, true, "s", "Join Small")
                  }
                >
                  Small
                  <span>(2x)</span>
                </div>

                <div
                  className="col-view v-center"
                  onClick={() =>
                    updateBettingDialogState(null, true, "o", "Join Odd")
                  }
                >
                  Odd
                  <span>(2x)</span>
                </div>

                <div
                  className="col-view v-center"
                  onClick={() =>
                    updateBettingDialogState(null, true, "e", "Join Even")
                  }
                >
                  Even
                  <span>(2x)</span>
                </div>
              </div>
            </div>
          </div>

          <div className="pd-0-15 mg-t-20">
            <div className={[styles.tabOptionsView]}>
              <div
                className={[
                  styles.viewCenter,
                  styles.tabOptionViewItem,
                  pageConst.tabActiveReord == "everyoneorder"
                    ? styles.tabSelectedView
                    : "",
                ].join(" ")}
                onClick={() => updateActiveTab("everyoneorder")}
              >
                Game History
              </div>
              <div
                className={[
                  styles.viewCenter,
                  styles.mgL10px,
                  styles.tabOptionViewItem,
                  pageConst.tabActiveReord == "myorder"
                    ? styles.tabSelectedView
                    : "",
                ].join(" ")}
                onClick={() => updateActiveTab("myorder")}
              >
                My History
              </div>
            </div>

            <div className="col-view br-10 mg-t-25 mg-b-15 bg-white">
              <div
                className={`col-view min-h ${
                  pageConst.tabActiveReord != "everyoneorder" ? "hide-v" : ""
                }`}
              >
                <div
                  className={[
                    styles.rowView,
                    styles.jcSpaceBet,
                    styles.fontS16Px,
                    styles.pd10px15px,
                    styles.colorWhite,
                    styles.borderTLR10px,
                    styles.bgCustom1,
                  ].join(" ")}
                >
                  <span>Period</span>
                  <span>Sum</span>
                  <span>Results</span>
                </div>

                <div
                  className={[
                    styles.colView,
                    styles.pd10px15px,
                    styles.gameResultListView,
                  ].join(" ")}
                >
                  {pageConst.gameRecordsList}
                </div>
              </div>

              <div
                className={`col-view min-h ${
                  pageConst.tabActiveReord != "myorder" ? "hide-v" : ""
                }`}
              >
                <div className={[styles.myGameRecordsView]}>
                  {pageConst.mygameRecordsList.map((data, index) => (
                    <div
                      key={data["m_order_id"]}
                      className={[styles.myGameRecordsViewItem].join(" ")}
                    >
                      <div
                        className={[
                          styles.myGameRecordsContents,
                          styles.rowView,
                          styles.jcSpaceBet,
                          styles.alignItemsCenter,
                        ].join(" ")}
                        onClick={() => updateSelectedRecord(data["m_order_id"])}
                      >
                        <div
                          className={[
                            styles.viewCenter,
                            styles.resultShortView,
                            getColourCodeVal(data["m_color"]) == "r"
                              ? styles.bgRed
                              : getColourCodeVal(data["m_color"]) == "g"
                              ? styles.bgGreen
                              : getColourCodeVal(data["m_color"]) == "rv"
                              ? styles.bgGreen
                              : getColourCodeVal(data["m_color"]) == "gv"
                              ? styles.bgGreen
                              : getColourCodeVal(data["m_color"]) == "b"
                              ? styles.bgOrange
                              : styles.bgBlue,
                          ].join(" ")}
                        >
                          {getColourCodeVal(data["m_color"]) == "b"
                            ? "big"
                            : getColourCodeVal(data["m_color"]) == "s"
                            ? "small"
                            : checkIfThisNumber(data["m_color"])
                            ? data["m_color"]
                            : ""}
                        </div>

                        <div
                          className={[
                            styles.colView,
                            styles.mygameShortDetailsView,
                          ].join(" ")}
                        >
                          <div
                            className={[
                              styles.w100,
                              styles.rowView,
                              styles.jcSpaceBet,
                            ].join(" ")}
                          >
                            <span className={[styles.letterSpacing1p1px]}>
                              {data["m_period_id"]}
                            </span>
                            <span
                              className={[
                                styles.gameShortDetailsResult,
                                data.m_status == "profit"
                                  ? styles.gameShortDetailsResultGreen
                                  : data.m_status == "loss"
                                  ? styles.gameShortDetailsResultRed
                                  : "",
                              ].join(" ")}
                            >
                              {data.m_status == "profit"
                                ? "success"
                                : data.m_status == "loss"
                                ? "Failed"
                                : ""}
                            </span>
                          </div>

                          <div
                            className={[
                              styles.w100,
                              styles.rowView,
                              styles.jcSpaceBet,
                              styles.mgT5px,
                            ].join(" ")}
                          >
                            <span className={[styles.fontS13Px]}>
                              {data.m_time_stamp}
                            </span>
                            <span
                              className={[
                                data.m_status == "profit"
                                  ? styles.colorGreen
                                  : styles.colorRed,
                              ]}
                            >
                              {data.m_status == "profit"
                                ? "₹" + data.m_profit
                                : data.m_status == "loss"
                                ? "-₹" + data.m_invested
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={[
                          styles.mygameRecordsDetails,
                          data["m_order_id"] != selectedRecord
                            ? styles.hideView
                            : "",
                        ].join(" ")}
                      >
                        <span className={[styles.mygameRecordsDetailsTitle]}>
                          Details
                        </span>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT10px,
                          ].join(" ")}
                        >
                          <span>Order Id</span>
                          <span>{data["m_order_id"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Period</span>
                          <span>{data["m_period_id"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Purchase Amount</span>
                          <span>₹{data["m_cost"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Quantity</span>
                          <span>{data["m_color_lot"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Tax</span>
                          <span className={[styles.colorRed]}>
                            ₹{data["m_fee"]}
                          </span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Result</span>
                          <span>{data["m_result"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Status</span>
                          <span>{data["m_status"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Win/Loss</span>
                          <span
                            className={[
                              data.m_status == "profit"
                                ? styles.colorGreen
                                : styles.colorRed,
                            ]}
                          >
                            {data.m_status == "profit"
                              ? "₹" + data.m_profit
                              : data.m_status == "loss"
                              ? "-₹" + data.m_invested
                              : ""}
                          </span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Order Time</span>
                          <span>{data["m_time_stamp"]}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <Link
                  className="w-100 v-center pd-10 mg-t-15 br-15 cl-golden txt-deco-n"
                  to={"/myorders"}
                >
                  View All Records
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default K3Lottery3Min;
