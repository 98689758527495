import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import { copyText } from "../modals/Constants";
import { getStorage, deleteStorage } from "../modals/Storage";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Activity() {
  const [pageConst, setGameData] = useState({
    pageTitle: "Activity",
    toastDialogShow: false,
    toastMessage: "",
  });

  const navigate = useNavigate();
  const signOutAccount = () => {
    if (deleteStorage()) {
      navigate("/login", { replace: true });
    }
  };

  const updateToastDialogState = (data, msg) => {
    setGameData((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setGameData((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };

  const copyMyId = () => {
    copyText(getStorage("uid"));
    updateToastDialogState(true, "ID Copied!");
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      signOutAccount();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog
          intentData={pageConst}
          updateState={updateToastDialogState}
        />

        <div className={[general.posRelative].join(" ")}>
          <div
            className={[
              general.colView,
              general.alignCenter,
              general.pd10px15px,
              general.bgCustomColor1,
            ].join(" ")}
          >
            <div className="col-view">
              <p className="ft-sz-25 cl-white">Activity</p>
              <p className="ft-sz-14 cl-white">
                Please remember to follow the event page.
              </p>
              <p className="ft-sz-14 cl-white">
                We will launch user feedback activities from time to time.
              </p>
            </div>
          </div>

          <div
            className={[general.colView, styles.activityContentsView].join(" ")}
          >
            <div
              className={[
                styles.activityOptionView,
                general.width100,
                general.rowView,
                general.spaceBetween,
              ].join(" ")}
            >
              <div>
                <img
                  src={require("../media/icons/activity_award_icon.png")}
                  alt="icon"
                />
                <span>Activity<br></br>Award</span>
              </div>

              <div>
                <img
                  src={require("../media/icons/invitation_bonus_icon.png")}
                  alt="icon"
                />
                <span>Invitation<br></br>Bonus</span>
              </div>

              <div>
                <img
                  src={require("../media/icons/betting_rebate_icon.png")}
                  alt="icon"
                />
                <span>Betting<br></br>Rebate</span>
              </div>

              <div>
                <img
                  src={require("../media/icons/superjackpot_icon.png")}
                  alt="icon"
                />
                <span>Super<br></br>Jackpot</span>
              </div>
            </div>

            <div className={[general.gridView2, general.mgT10px].join(" ")}>
              <Link
                className={[
                  general.textDecoNone,
                  general.colView,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
                to={"/redeemgiftcard"}
              >
                <img
                  src={require("../icons/othericons/1.png")}
                  className="w-100"
                  alt="icon"
                />
                <div className="col-view pd-15-10 mg-t-10">
                  <span
                    className={[general.fontS18Px, general.colorBlack].join(
                      " "
                    )}
                  >
                    Gifts
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorGrey].join(
                      " "
                    )}
                  >
                    Enter the redemption code to receive gift rewards
                  </span>
                </div>
              </Link>

              <Link
                className={[
                  general.textDecoNone,
                  general.colView,
                  general.borderRadius10px,
                  general.bgWhite,
                ].join(" ")}
                to={"/dailycheckin"}
              >
                <img
                  src={require("../icons/othericons/2.png")}
                  className="w-100"
                  alt="icon"
                />
                <div className="col-view pd-15-10 mg-t-10">
                  <span
                    className={[general.fontS18Px, general.colorBlack].join(
                      " "
                    )}
                  >
                    Attendance bonus
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorGrey].join(
                      " "
                    )}
                  >
                    The more consecutive days you sign in, the higher the reward
                    will be.
                  </span>
                </div>
              </Link>
            </div>

            <Link
      className={[
        styles.activityListView,
        general.bgCustomColor4,
        general.mgT15px,
      ].join(" ")}
      to="/firstdepositbonus"
    >
      <img
        src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhur8Rkd_HGJ0i5Jxlas7t5v_m2IFiDNKPvJkVE7gfHEO-CVKs4JvhAev6eJu2z67rSF2NOZ5XIbX7y8Arf4CP5EdwZHbLWzLwk3VTJ-O2P4DOaoAb5-ZP1pud8paPMUJKY3VXMmwG4ZRWY61hc8t0-HcLyZWEVpkmJLNPI0vaE4vjVzFyiLl62n59fhLE/s320/Untitled%20design%20(1).png"
        alt="image"
      />
      <span>💸 First Deposit Bonus 💸</span>
    </Link>

    <Link
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT10px,
              ].join(" ")}
              to="/dailycheckin"
            >
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiQ3JtVg6Kmw3kn6D8wAfP5mO-NqCPLUUfub7sN7XccolRwyivsLw8Bgxk8SzgVpVJlUnplolxPAnvRb2iwA8am1YJVAWtucPmqc3j2fjTsIEN0EHu2cKkY-w-UYTvMCMLbtyvpLGI1hp76h1YcD0pSeW5g884nypVPi5fu8r4G1BsIPEaejSIddqkpF-E/s320/bonus.png"
                alt="image"
              />

              <span>💰 Get Daily Check-in Bonus💰</span>
              </Link>

            <Link
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT10px,
              ].join(" ")}
              to="/randombonus"
            >
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjWdEGux4IZckY_gKkyFUjCbkjd3JJj1E7jR-N-H3debElI2vT8YqRHv6upkwRvkOy0ptF8aHPGYjY2vRT6D2JF0exLCoUoSlhXC3tKZK-HnXoj36oLOkrCFuX8SrRyWxw4RsS6OWPvBN4mTO9KJcVpHjKm5UmuRY7EGgImOwxvfvUuo6Eg09G_dxTHeDk/s320/rand-bonus.png"
                alt="image"
              />

              <span>❗️ Mysterious Gift ❗️</span>
              </Link>
          </div>
        </div>

        <BottomNavbar activeBar="activity" />
      </div>
    </div>
  );
}

export default Activity;