import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/navbar.css";
import general from "../css/General.module.css";

const GameTopBar = ({
  intentData,
  multiBtn,
  multiBtn1,
  multiBtn2,
  updateState,
}) => {
  const navigate = useNavigate();

  return (
    <nav
      className={[
        general.posFixed,
        general.top0,
        general.rowView,
        general.spaceBetween,
        general.respWidth,
        general.height45px,
        general.pd10px,
        general.zIndex100,
        general.appContentsBackground,
      ].join(" ")}
    >
      <div className={[general.rowView]}>
        <Link
          className={`${multiBtn1 == "" ? "" : "hide-v"}`}
          onClick={() => navigate(-1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#000000"
          >
            <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
          </svg>
        </Link>

        <span
          className={[
            general.fontS18Px,
            general.mgL10px,
            intentData.pageTitle == "" ? general.hideView : "",
          ].join(" ")}
        >
          {intentData.pageTitle}
        </span>
      </div>

      <span
        className={[
          general.fontS18Px,
          multiBtn == "" ? general.hideView : "",
        ].join(" ")}
        onClick={() => updateState("multiBtn2")}
      >
        {multiBtn2}
      </span>
    </nav>
  );
};

export default GameTopBar;
